import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`EMPOWER YOUR LIFE`}</h1>
    <h2>{`COMPREHENSIVE MANAGEMENT OF LONG STANDING MEDICAL CONDITIONS THROUGH PERSON CENTERED APPROACH`}</h2>
    <p>{`Self-management of long standing medical condition through person focused approach is a modality of care that PCMH restore health clinic facilitates people through a multi-interventional and integrated approach of health care.`}</p>
    <div style={{
      "margin": "1rem",
      "backgroundColor": "cyan",
      "borderRadius": "10px",
      "padding": "3rem"
    }}>
      <h3>{`Patient Story`}</h3>
      <p>{`Mr K, 45 year old visited doctor with complaints of tiredness, weakness and frequent urination, the doctor advised him to take a few tests and he was diagnosed with diabetes and thyroid issues. Doctor advised him to take 5 medications every day along with few diet modifications. K went walking back home and it kept ringing in his ear, "you will have to take these medications for a lifetime!” He felt sick thinking about it. How he had been well until this morning but this consultation and diagnosis made him feel like a patient. `}</p>
      <p>{`"Will I be able to eat the laddoos I like? Will I be able to travel without worrying anymore? What about my daughter, would she get it as well because of me? Is my health under my control anymore? ”and several other questions ran through his mind. It was difficult to explain how he felt to his wife/family but he followed the doctor's advice very well. He took the 5 tablets every day and made a few changes in his diet, removed sugar and sweets from his life. In spite of these changes, he wasn't sure he felt better or felt under control. He often lost in thoughts like "why did I get this? What will happen to me?”  This eventually led him to have depression, not able to fully enjoy social gatherings and engage with family like before. Wife sensed this discomfort in the husband and they felt they needed more guidance on how to deal with his condition and also answer their apprehensions. `}</p>
    </div>
    <h3>{`What is a long standing medical condition?`}</h3>
    <p>{`A long standing medical condition, called as chronic diseases are those that are likely to be present for a longer time. A long standing medical condition could be communicable diseases like HIV, Hepatitis B and C or non-communicable health conditions like diabetes, hypertension psychological concerns such as depression etc. They result from a combination of physiological, psychological, environmental and genetic factors and hence the management of chronic illness involves taking into account all these factors and caring for the individual.`}</p>
    <p>{`Often chronic condition can be controlled and may or may not have complete cure and thus has a huge impact on you and your family’s life in many ways.     `}</p>
    <h3>{`Examples of chronic illness:`}</h3>
    <p>{`Non communicable diseases (NCD) are diseases that are not transmitted directly from one person to another. Some examples of non-communicable diseases are:`}</p>
    <ul>
      <li parentName="ul">{`Diabetes`}</li>
      <li parentName="ul">{`Hypertension`}</li>
      <li parentName="ul">{`Mood disorders`}</li>
      <li parentName="ul">{`Personality disorders`}</li>
      <li parentName="ul">{`Cardiovascular diseases`}</li>
      <li parentName="ul">{`Asthma`}</li>
      <li parentName="ul">{`Cancer, etc`}</li>
    </ul>
    <p>{`Communicable disease is a disease that is transmitted directly from one person to another. Few examples of communicable diseases are:`}</p>
    <ul>
      <li parentName="ul">{`HIV and AIDS`}</li>
      <li parentName="ul">{`Hepatitis B and C`}</li>
    </ul>
    <h3>{`Why  chronic disease condition is a threat?`}</h3>
    <p>{`NCDs dominate the list of the top causes of death in India. In 2016 a staggering 28.1% of all deaths in India were caused by heart conditions. Overall, 61.8% of all deaths in the country were caused by NCDs in 2016. `}<strong parentName="p">{`One in four Indians`}</strong>{` are at risk of dying before the age of seventy due to an NCD (Health issues India, 2019).`}</p>
    <p>{`In the above given example Mr. K  had to go through several challenges and the tension which was created due to the chronic condition lead him to self-isolation and depression having a huge impact on him and  his family’s  life.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "720px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/d043a074711e6fbe9d618f8e035c5e76/80e3c/chronic-illness-cycle.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAEDAgX/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAAB72XGLjK//8QAGBABAAMBAAAAAAAAAAAAAAAAAQAQMRL/2gAIAQEAAQUCmAiOHdf/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAaEAACAgMAAAAAAAAAAAAAAAAAEQEQITFB/9oACAEBAAY/AhjgwRpdr//EABkQAAMBAQEAAAAAAAAAAAAAAAABESFBYf/aAAgBAQABPyG7CpmsFtlTEdd+lNIkP//aAAwDAQACAAMAAAAQuA//xAAWEQADAAAAAAAAAAAAAAAAAAAQEUH/2gAIAQMBAT8QjH//xAAWEQEBAQAAAAAAAAAAAAAAAAARECH/2gAIAQIBAT8QdJ//xAAcEAEBAQABBQAAAAAAAAAAAAABEQAhMUFRgbH/2gAIAQEAAT8QSwXm4oCDENBMqPnF4V9OzFy5Mnj38wAgBv/Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "chronic illness cycle",
            "title": "chronic illness cycle",
            "src": "/static/d043a074711e6fbe9d618f8e035c5e76/80e3c/chronic-illness-cycle.jpg",
            "srcSet": ["/static/d043a074711e6fbe9d618f8e035c5e76/f93b5/chronic-illness-cycle.jpg 300w", "/static/d043a074711e6fbe9d618f8e035c5e76/b4294/chronic-illness-cycle.jpg 600w", "/static/d043a074711e6fbe9d618f8e035c5e76/80e3c/chronic-illness-cycle.jpg 720w"],
            "sizes": "(max-width: 720px) 100vw, 720px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Fig 1: Health deterioration cycle with long standing Medical condition`}</strong></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "735px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/b6b758cae9b60c4c9b2f61d8458f2596/7608e/chronic-illness-needs.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "63%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsTAAALEwEAmpwYAAABnUlEQVQ4y4WTaaoCQQyE+5+/BMEjeAVvoHf1CIPnUFFxGXFDx33fjXyBEhUeL1DTWzqpSnrCarWy/X5vu93uC4fDwY7Ho599gn2w3W6/9nUvjMdjA5PJxDEajSxJErvf73Y+n+3XLpeLn+HT6/XcfzAYeAwPyOfxeLgTYH46nXyExecZwF/JhsOhNRoNazab7su9MJ/Prd1uWxzHnrHT6ThTDCnP5/OLIXsYQTESyud2u1kgMhD19XrtLK7Xq202G5cIg2q16rJggZG01WpZt9t1QsxJFvgQmQZQbObIYcSiKLJMJmPZbNaKxeI7iRKzhhCjB2STTpNxOp16sQkME6SUSiVLp9OWy+Usn8/bcrn0RCSVXMCcRAFmyFPRmeOsGpXLZUulUo5CoeCsYL9YLN5SKUm/33emgQOawWa9Xnen2WzmlySPRtFNVKgsaghKVFfWgQuSIBkA4/JfXVZZUKQe+LNRUekw4BmxR1C9UUHvkCSSXKvVrFKpuPT3wyYgcmgIjqzJrMb8/ilAzLijPwsEDv4zdfIzuMry6/cC3WfQlm7gT7wAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "chronic illness needs",
            "title": "chronic illness needs",
            "src": "/static/b6b758cae9b60c4c9b2f61d8458f2596/7608e/chronic-illness-needs.png",
            "srcSet": ["/static/b6b758cae9b60c4c9b2f61d8458f2596/5a46d/chronic-illness-needs.png 300w", "/static/b6b758cae9b60c4c9b2f61d8458f2596/0a47e/chronic-illness-needs.png 600w", "/static/b6b758cae9b60c4c9b2f61d8458f2596/7608e/chronic-illness-needs.png 735w"],
            "sizes": "(max-width: 735px) 100vw, 735px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`Fig 2: Explains the physical and psychological needs of people with chronic conditions`}</strong></p>
    <h2>{`Our Model`}</h2>
    <p>{`In line with the chronic care model, our care approach  comprises of a mental health provider, psychologist for counseling and therapy along with our team of clinical health provider, a family physician and clinical pharmacologist where we diagnose your condition (Assess) work with you to identify your needs and concerns (Assist), work together to create a personalized care plan (arrange and action).`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "775px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/31d0587bf41db7478d97beaa92e2e8ed/0098c/chronic-illness-model.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "93.66666666666667%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAATCAYAAACQjC21AAAACXBIWXMAAAsTAAALEwEAmpwYAAAD20lEQVQ4y2WUaU8bVxSG/VOqfovyB6pKVfq5Kv0QfgJdUFS1kdp+ahpVSlOaLk5FSNM0iRUwaakI0EKLwxYSaNhLbQiLF8BgDNgxHs/iGcYz46d3xrHZrnR9zrnj+57lfXV9iFUul73trt1MjtVEkvRuloWlGNHEJomNFJnsvvCTbGztePHiSoJt8Z+T931Vp2pLpRK6rlOyLDTdQD8wOTBLWLYtzg1M8d0wDlC1IgfiWxWsun1H0SvA4AirFg00VUWXJVRFQVGLKOLMtboAPLlOVehWJqs6kiSxvzSJOt1F4XGAzYEAywMPiAx3MT8+SCQSYWl5hWgsTm5fQivqWJZdA/W5jqJqSLKKZZrsjHWy0nqZTK+feP9dnnTcZLDVz0jwOlOdPxEe6WZ6apJQqJ/JyQkURaUgtqrplQplEWhiHt4yFOYDlxn58ROm718l3B9kKNjMw5tX6f65iWedt1gMBRns76MtGKS9vZ38/r53VVY0bxw+96fGlFttfJZs/w2yf37HZl8Ls923me+9R7gvwHTPHeaehJiZnWNmZoZEIiHatbCdythk0amvOuCjxOCIJNkVXvw7wEjPA/7uDNIRuEWo7w/GxscFSRK2ZZ4ixgMsCuY8DA+w7GVbjGdJ5SqJiqbDZjpLNL7uxWbJZm0rz3oq78UFrUTfWIKl9byQkYFPKiiCYauWxTiwGJvbZmE148W/DyX4oGmU5J47GlsQYDA8keTx1Ca20GYmb3Cjc5n+Z0mhUzFD9zAvyQLIpFCQWRCyKJk6jl0iFouxGk14IK6Io9Eoe3u7XqK93TQLi8+FyF12HWGLojunIht3frphUCxqZDK7YtAl75I79PR26oh4Hc+6RZRFQtsUYheilwWxVQpqwjZNm9lIku7QPM2BIcZnYhRFC3lRtaszSVaEr3gVjU7Hee/LHq4HJ0juFCocOOVDYVu2Q1vXJLfbR+kd+o+Rf5ZZ38q9ZNzjyrMvC2Qvp9E1vII/OEXjlT7WUvs1UA+wqFu0/xUjEpXEY1BGMypV7xQ22JLibMuidWWNlPDT0sYxqYyHd3g6nz4ErLa8vC7T2rtOS8cq/tY1RiKzXBqs59Pu83z86zt82Po2n/XU8/mjesKbz2l5mOSLewtcbA6zk9Nr0jv12ihFk42URWR7loa213j37pu8f/8cDXfeoOGXczT+9jrj0Wm+Fkm7nm4ha+YRHXPIslMDrQxq9cUMb337CnVXzlDvP8v5H85S99UZ6vyvspYPH2vbOfKmHntgPWCnAphV03w/9BHfhBq59ugC1wYu0CR8//BFctoeFTJtTt7/H2kfXwsEtVZDAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "chronic illness model",
            "title": "chronic illness model",
            "src": "/static/31d0587bf41db7478d97beaa92e2e8ed/0098c/chronic-illness-model.png",
            "srcSet": ["/static/31d0587bf41db7478d97beaa92e2e8ed/5a46d/chronic-illness-model.png 300w", "/static/31d0587bf41db7478d97beaa92e2e8ed/0a47e/chronic-illness-model.png 600w", "/static/31d0587bf41db7478d97beaa92e2e8ed/0098c/chronic-illness-model.png 775w"],
            "sizes": "(max-width: 775px) 100vw, 775px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <h2>{`Take home messages:`}</h2>
    <ul>
      <li parentName="ul">{`Long standing medical conditions require guided care for effective management of health.`}</li>
      <li parentName="ul">{`Pills alone sometimes do not cure a condition as they require a customized and consistent multidisciplinary care.`}</li>
      <li parentName="ul">{`Peaceful lifestyle by enhancing emotional and mental wellbeing through holistic care is a requisite for quality living with the long standing medical condition.`}</li>
      <li parentName="ul">{`Pharmacological assessment of pill burden and personalized care plan is an added advantage to the advancement of better health.`}</li>
    </ul>
    <p><em parentName="p">{`“When a long standing medical condition is a way of illness, managing it is a way of life”`}</em></p>
    <h3>{`Bibliography`}</h3>
    <div style={{
      "margin": "2rem",
      "textIndent": "-2rem"
    }}>
      <p>{`Health issues India. (2019). Health issues india. Retrieved november 16, 2019, from Health issues india: `}<a parentName="p" {...{
          "href": "https://www.healthissuesindia.com/noncommunicable-diseases/"
        }}>{`https://www.healthissuesindia.com/noncommunicable-diseases/`}</a></p>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      